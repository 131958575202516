import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenControllerService } from 'src/app/token-controller.service';
import { environment } from 'src/environments/environment';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})

export class GatewayControllerService {

  private downLoadFile(fileName: string, data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenControllerService
    ) { }

  /**
   * http post the array
   * @param gateways a array of gateway
   */  
  importGateways(gateways: Array<any>): Observable<any> {
    return this.httpClient.post(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/', gateways, {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  getGateway(uuid: string): Observable<any> {
    return this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/' + uuid, {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  getAllGateways(): Observable<any> {
    return this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/gateways/', {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  getGatewayLogFiles(uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/' + uuid + '/logfiles', {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Bearer ' + this.tokenService.showStoredToken()
        }),
        responseType: 'blob'
      }).subscribe(
        (archive: Blob) => {
          this.downLoadFile(uuid, archive, archive.type);
          resolve({fileName: uuid + '.zip', archive});
        }
      );
    });
  }

  // returns an array of the last log objects
  getPluginLastLogs(uuid: string, uid: string): Observable<any> {
    return this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/' + uuid + '/plugins/' + uid + '/lastlogs', {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  getPluginLogFiles(uuid: string, uid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/' + uuid + '/plugins/' + uid + '/logfiles', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.tokenService.showStoredToken()
        }),
        responseType: 'blob'
      }).subscribe(
        (archive: Blob) => {
          this.downLoadFile(uid, archive, archive.type);
          resolve({ fileName: uid + '.zip', archive });
        }
      );
    });
  }

  getPluginLastLogFile(uuid: string, uid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/' + uuid + '/plugins/' + uid + '/lastlogfile', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.tokenService.showStoredToken()
        }),
        responseType: 'blob'
      }).subscribe(
        (logFile: Blob) => {
          this.downLoadFile(uid + '_preview.log', logFile, logFile.type);
          resolve({ fileName: uid + '_preview.log', logFile });
        }
      );
    });
  }

  // returns the gateway's activities
  getGatewayActivities(uuid: string): Observable<any> {
    return this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/devices/' + uuid + '/activities/', {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

}
