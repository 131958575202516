import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, ToastController } from '@ionic/angular';
import { FeaturePopoverComponent } from './popovers/feature-popover/feature-popover.component';
import { Socket } from 'ngx-socket-io';
import { GatewayControllerService } from '../gateways/services/gateway-controller.service';
import { TranslateService } from '@ngx-translate/core';

export interface LioShortlistOptions {
  icon: string;
  title: string;
  type: ShortListTypeEnum;
  dataset: any[];
}

export enum ShortListTypeEnum {
  DEVICES,
  PLUGINS,
  FEATURES,
  FEATURESTATES,
  FEATUREMODELS,
  GATEWAYS,
  CUSTOMERS,
  USERS
}

@Component({
  selector: 'lio-shortlist',
  templateUrl: './lio-shortlist.component.html',
  styleUrls: ['./lio-shortlist.component.scss'],
})
export class LioShortlistComponent implements OnInit {

  private shortListTypeEnum = ShortListTypeEnum;

  @Input() options: LioShortlistOptions = {
    icon: 'help',
    type: ShortListTypeEnum.DEVICES,
    title: '',
    dataset: []
  };

  constructor(
    private router: Router,
    public popoverController: PopoverController,
    private socket: Socket,
    private gatewayControllerService: GatewayControllerService,
    private toastController: ToastController,
    private translateService: TranslateService
    ) { }

  ngOnInit() {

    switch (this.options.type) {
      case ShortListTypeEnum.PLUGINS:

        break;

      default:
        break;
    }
  }

  onClick(ev: any, d: any) {
    switch (this.options.type) {
      case ShortListTypeEnum.DEVICES:
        this.router.navigateByUrl('/devices/' + d.uuid);
        break;
      case ShortListTypeEnum.GATEWAYS:
        this.router.navigateByUrl('/gateways/' + d.uuid);
        break;
      case ShortListTypeEnum.FEATURES:
        //this.presentFeaturePopover(ev, d);
        break;
        case ShortListTypeEnum.FEATURESTATES:
          break;
        case ShortListTypeEnum.FEATUREMODELS:
          console.log(d);
          
          break;
      case ShortListTypeEnum.PLUGINS:
        d.logVisible = d.logVisible ? !d.logVisible : true;
      default:
        console.log(d);
    }
  }

  showHistory(ev: Event, d: any) {
    d = {data: 'history'}; // MOCKUP
    this.presentFeaturePopover(ev, d);
  }

  downloadLastLogFile(ev: Event, d: any) {
    ev.stopPropagation();
    // TODO: Toast message and loading spinner
    this.gatewayControllerService.getPluginLastLogFile(d.gatewayUuid, d.uid).then(
      (res: any) => {
        console.log(res);
        this.presentToast(res.fileName);
      }
    );
  }

  downloadLogFiles(ev: Event, d: any) {
    ev.stopPropagation();
    // TODO: Toast message and loading spinner
    this.gatewayControllerService.getPluginLogFiles(d.gatewayUuid, d.uid).then(
      (res: any) => {
        console.log(res);
        this.presentToast(res.fileName);
      }
    );
  }

  downloadGatewayLogFiles(ev: Event, gatewayUuid: any) {
    // TODO: Toast message and loading spinner
    this.gatewayControllerService.getGatewayLogFiles(gatewayUuid).then(
      (res: any) => {
        console.log(res);
        this.presentToast(res.fileName);
      }
    );
  }

  async presentFeaturePopover(ev: any, f: any) {
    const popover = await this.popoverController.create({
      component: FeaturePopoverComponent,
      event: ev,
      translucent: true,
      componentProps: {
        data: f.data
      }
    });
    return await popover.present();
  }

  async presentToast(fileName: string) {
    const toast = await this.toastController.create({
      message: this.translateService.instant('LOGS.DOWNLOAD_FILE', {fileName}),
      duration: 2000
    });
    toast.present();
  }
}
