import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Data, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenControllerService {

  constructor(private httpClient: HttpClient, private router: Router, private cookieService: CookieService) { }

  getToken(user: any): Observable<any> {
    return this.httpClient.post(environment.httpProtocol + '://' + environment.address + '/api-v1/users/auth/login', user, {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // Authorization: 'Bearer ' + `"ATB8ZShU$]r:Aam=4o_T}9AG/YkaKk)#>y-/YH+b^)+BM{Ekb*%;Cdb*}wynA7P?,8[rH[xm{%Ju3`
        Authorization: 'Bearer ' + `!te]7[=9x6H8;wTv_h@\\U];\`cvRP4&9@eUw-be7!K<@IT&"n22;Sr6K@G0\\^;JHEP0>u\`?(4Cb<]vZH`
      })}
    );
  }

  showStoredToken(): string {
    return this.cookieService.get('authToken');
  }

  async isAuthenticated() {
    // get the auth token from Cookies
    const token = this.cookieService.get('authToken');
    let authenticated = false;

    // check if token is set, then...
    if (token) {
      await this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/users/auth/check', {
          headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Bearer ' + token
        })
      }).toPromise().then(
        (result: any) => {
            if (result.ok) {
              if (!this.cookieService.check('username')) {
                this.cookieService.set('username', result.ok.login, 50, '/', undefined, false, 'Strict');
              }
              authenticated = true;
            }
          }
      ).catch(
        error => {
                console.log(error);
                if (error.status === 401) {
                  authenticated = false;
                }
              }
      );
    }
    return authenticated;
  }

  forgetMe() {
      this.cookieService.delete('username', '/');
      this.cookieService.delete('authToken', '/');
  }
}
