import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FirmwareControllerService } from '../firmwares/services/firmware-controller.service';

export enum ListTypeEnum {
  GATEWAYS,
  DEVICES,
  MODELS,
  PRODUCTION_ORDERS,
  MANUFACTURERS,
  FIRMWARES,
  USERS,
  ENDUSERS,
  REALMS,
  ACTIVITIES
}

export interface LioListOptions {
  icon: string;
  columns: {attribute: string, label: string}[];
  type: ListTypeEnum;
  dataset: any[];
  sort: {prop: string, dir: string};
}

@Component({
  selector: 'lio-list',
  templateUrl: './lio-list.component.html',
  styleUrls: ['./lio-list.component.scss'],
})
export class LioListComponent implements OnInit {

  private listTypeEnum = ListTypeEnum;

  @Input() filters: any = {};
  @Input() options: LioListOptions = {
    icon: 'help',
    type: ListTypeEnum.GATEWAYS,
    columns: [],
    dataset: [],
    sort: {prop: '', dir: ''}
  };

  constructor(
    private router: Router,
    private firmwareController: FirmwareControllerService
    ) { }

  ngOnInit() {}

  goToDetail(event) {
    if (event.type === 'click') {
        console.log(event.row);
        const selectedRow = event.row;
        switch (this.options.type) {
          case ListTypeEnum.MODELS:
            this.router.navigateByUrl(this.router.url + '/' + selectedRow.id);
            break;
          case ListTypeEnum.REALMS:
            this.router.navigateByUrl(this.router.url + '/' + selectedRow.id);
            break;
          case ListTypeEnum.PRODUCTION_ORDERS:
            //break;
          case ListTypeEnum.ENDUSERS:
            //break;
          case ListTypeEnum.FIRMWARES:
            //break;
          case ListTypeEnum.MANUFACTURERS:
            //break;
          case ListTypeEnum.USERS:
            //break;
          case ListTypeEnum.ACTIVITIES:
            break;
          default:
            this.router.navigateByUrl(this.router.url + '/' + selectedRow.uuid);
            break;
        }
    }
  }

  getActivityMessage(row: any): string {
    return JSON.parse(row.data).message;
  }

  activateFirmware(row: any){
    this.firmwareController.activateFirmware(row.manufacturerId,row.modelId,row.id).subscribe(
      res => {
        console.log(res);
      }
    );
  }

  hasTechno(row: any, techno: string): boolean {
    return false;
    if (row.plugins.some(e => e.technology.icon === techno)) {
      return true;
    } else {
      return false;
    }
  }
}
