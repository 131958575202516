import { Component, NgZone } from '@angular/core';

import { Platform, Events, IonSplitPane, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Socket } from 'ngx-socket-io';
import { TokenControllerService } from './token-controller.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private appLanguage: string;
  private navigate: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private events: Events,
    private zone: NgZone,
    private menuController: MenuController,
    private socket: Socket,
    private tokenControllerService: TokenControllerService
  ) {
    this.translateService.setDefaultLang('en-EN');
    if (this.cookieService.check('userLang')) {
      this.appLanguage = this.cookieService.get('userLang');
    } else {
      this.appLanguage = navigator.language;
    }
    this.initializeApp();
  }

  initializeApp() {
    // Initializing Socket
    this.socket.connect();
    this.socket.emit('authentication', {token: this.tokenControllerService.showStoredToken()});
    this.socket.fromEvent('unauthorized').subscribe(
      (data: any) => {
        console.log(data);
      }
    );

    this.translateService.use(this.appLanguage).toPromise()
    .catch(
      (v) => {
        this.appLanguage = 'en-EN';
        this.translateService.currentLang = 'en-EN';
        this.sideMenu();
      }
    )
    .then(
      (v) => {
        this.sideMenu();
      }
    );

    this.events.subscribe('language:switched',
      (v) => {
        this.sideMenu();
      }
    );
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  sideMenu() {
    this.navigate =
    [
      {
        title : this.translateService.instant('MENU.HOME'),
        url   : '/home',
        icon  : 'home'
      },
      {
        title : this.translateService.instant('MENU.DEVICES'),
        url   : '/devices',
        icon  : 'flash'
      },
      {
        title : this.translateService.instant('MENU.GATEWAYS'),
        url   : '/gateways',
        icon  : 'analytics'
      },
      {
        title : this.translateService.instant('MENU.MODELS'),
        url   : '/models',
        icon  : 'pricetags'
      },
      {
        title : this.translateService.instant('MENU.PRODUCTION_ORDERS'),
        url   : '/production-orders',
        icon  : 'build'
      },
      {
        title : this.translateService.instant('MENU.REALMS'),
        url   : '/realms',
        icon  : 'cube'
      },
      // {
      //   title : this.translateService.instant('MENU.ADMINS'),
      //   url   : '/admins',
      //   icon  : 'contact'
      // },
      // {
      //   title : this.translateService.instant('MENU.INSTALLERS'),
      //   url   : '/installers',
      //   icon  : 'hammer'
      // },
      // {
      //   title : this.translateService.instant('MENU.MANUFACTURERS'),
      //   url   : '/manufacturers',
      //   icon  : 'business'
      // },
      {
        title : this.translateService.instant('MENU.ENDUSERS'),
        url   : '/endusers',
        icon  : 'contacts'
      },
      {
        title : this.translateService.instant('MENU.USERS'),
        url   : '/users',
        icon  : 'person'
      },
      {
        title : this.translateService.instant('MENU.SETTINGS'),
        url   : '/settings',
        icon  : 'settings'
      },
      // {
      //   title : this.translateService.instant('MENU.STATISTICS'),
      //   url   : '/statistics',
      //   icon  : 'stats'
      // }
    ];
  }
}
