import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LioHeaderButtonsComponent } from '../lio-header-buttons/lio-header-buttons.component';
import { IonicModule } from '@ionic/angular';
import { LioListComponent } from '../lio-list/lio-list.component';
import { LioListFilterComponent } from '../lio-list-filter/lio-list-filter.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LioDetailComponent } from '../lio-detail/lio-detail.component';
import { LioShortlistComponent } from '../lio-shortlist/lio-shortlist.component';
import { TranslateModule } from '@ngx-translate/core';
import { LioLogViewerComponent } from '../lio-log-viewer/lio-log-viewer.component';
import { LioActuatorComponent } from '../lio-actuator/lio-actuator.component';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [
    LioLogViewerComponent,
    LioHeaderButtonsComponent,
    LioDetailComponent,
    LioListComponent,
    LioShortlistComponent,
    LioListFilterComponent,
    LioActuatorComponent
  ],
  exports: [
    LioLogViewerComponent,
    LioHeaderButtonsComponent,
    LioDetailComponent,
    LioListComponent,
    LioShortlistComponent,
    LioListFilterComponent,
    LioActuatorComponent,
    TranslateModule
  ]
})

export class SharedModule {}
