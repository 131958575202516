import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenControllerService } from 'src/app/token-controller.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmwareControllerService {

  constructor(private httpClient: HttpClient, private tokenService: TokenControllerService) { }

  createFirmware(manufacturerId: number, modelId: number, firmware: any, files: FileList): Observable<any> {

    let fileToUpload = files.item(0);
    let formData = new FormData();
    formData.append('firmwareFile', fileToUpload, fileToUpload.name);
    formData.append('name', firmware.name);
    formData.append('version', firmware.version);
    // formData.append('manufacturerId', firmware.manufacturerId);
    formData.append('active', firmware.active);
    formData.append('dfu', firmware.dfu);

    return this.httpClient.post(environment.httpProtocol + '://' + environment.address + '/api-v1/models/' + manufacturerId + '/' + modelId + '/firmwares', formData, {
        headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  activateFirmware(manufacturerId: number, modelId: number, id: number): Observable<any> {
    return this.httpClient.put(environment.httpProtocol + '://' + environment.address + '/api-v1/models/' + manufacturerId + '/' + modelId + '/firmwares/'+ id +'/activate', {
        headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  getFirmware(modelId: number, id: number): Observable<any> {
    return this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/models/' + modelId + '/firmwares/' + id, {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  getAllFirmwares(): Observable<any> {
    return this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/models/firmwares/', {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }

  getAllFirmwaresFromModel(modelId: number): Observable<any> {
    return this.httpClient.get(environment.httpProtocol + '://' + environment.address + '/api-v1/models/' + modelId + '/firmwares/', {
        headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + this.tokenService.showStoredToken()
      })
    });
  }
}
