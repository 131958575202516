import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', canActivate: [AuthGuardService], loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'admins', canActivate: [AuthGuardService], loadChildren: './admins/admins-list/admins-list.module#AdminsListPageModule' },
  { path: 'gateways', canActivate: [AuthGuardService], loadChildren: './gateways/gateways-list/gateways-list.module#GatewaysListPageModule' },
  { path: 'gateways/add', canActivate: [AuthGuardService], loadChildren: './gateways/add-gateway/add-gateway.module#AddGatewayPageModule' },
  { path: 'gateways/:uuid',  canActivate: [AuthGuardService], loadChildren: './gateways/gateway-detail/gateway-detail.module#GatewayDetailPageModule' },
  { path: 'devices', canActivate: [AuthGuardService], loadChildren: './devices/devices-list/devices-list.module#DevicesListPageModule' },
  { path: 'devices/:uuid', canActivate: [AuthGuardService], loadChildren: './devices/device-detail/device-detail.module#DeviceDetailPageModule' },
  { path: 'models', canActivate: [AuthGuardService], loadChildren: './models/models-list/models-list.module#ModelsListPageModule' },
  { path: 'models/add', canActivate: [AuthGuardService], loadChildren: './models/add-model/add-model.module#AddModelPageModule' },
  { path: 'models/:modelId/edit', canActivate: [AuthGuardService], loadChildren: './models/add-model/add-model.module#AddModelPageModule' },
  { path: 'models/:uuid', canActivate: [AuthGuardService], loadChildren: './models/model-detail/model-detail.module#ModelDetailPageModule' },
  { path: 'models/:manufacturerId/:modelId/firmwares/add', canActivate: [AuthGuardService], loadChildren: './firmwares/add-firmware/add-firmware.module#AddFirmwarePageModule' },
  { path: 'endusers', canActivate: [AuthGuardService], loadChildren: './endusers/endusers-list/endusers-list.module#EndusersListPageModule' },
  { path: 'users', canActivate: [AuthGuardService], loadChildren: './users/users-list/users-list.module#UsersListPageModule' },
  { path: 'users/add', canActivate: [AuthGuardService], loadChildren: './users/add-user/add-user.module#AddUserPageModule' },
  { path: 'realms', canActivate: [AuthGuardService], loadChildren: './realms/realms-list/realms-list.module#RealmsListPageModule' },
  { path: 'realms/:uuid', canActivate: [AuthGuardService], loadChildren: './realms/realm-detail/realm-detail.module#RealmDetailPageModule' },
  { path: 'production-orders', canActivate: [AuthGuardService], loadChildren: './production-order/production-order-list/production-order-list.module#ProductionOrderListPageModule' },
  { path: 'production-orders/add', canActivate: [AuthGuardService], loadChildren: './production-order/add-production-order/add-production-order.module#AddProductionOrderPageModule' },
  { path: 'settings', canActivate: [AuthGuardService], loadChildren: './settings/settings.module#SettingsPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: '**', redirectTo: 'home' },
// TODO: create 404 component and redirect to.
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
